<template>
  <div>
    <div class="contentsWidth mx-auto">
      <div class="row col-12">
        <p class="h5" v-if="!id">記入項目管理 - 登録</p>
        <p class="h5" v-if="id">記入項目管理 - 編集</p>
      </div>

      <div id="elementaryWrap" class="mt-2">
        <b-row class="mt-2">
          <b-col sm="2" class="inputLabel">
            <label>名称</label>
          </b-col>
          <b-col sm="4">
            <b-form-input :value="name" @input="updateName" placeholder="入力してください。"></b-form-input>
          </b-col>
          <b-col sm="2" class="inputLabel">
            <label>入力方法</label>
          </b-col>
          <b-col sm="4">
            <b-form-select
              :value="type"
              @change="updateType"
              :options="this.CONST_FIELD_TYPE">
              <template v-slot:first>
                <option :value="0">-- 選択してください。 --</option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col sm="2" class="inputLabel">
            <label>グループ</label>
          </b-col>
          <b-col sm="4">
            <b-form-select
              :value="group_id"
              @input="updateGroup"
              :options="groupList">
              <template v-slot:first>
                <option :value="''">-- 未選択 --</option>
              </template>
              <template v-slot>
                <option :value="0">+ 新規作成</option>
              </template>
            </b-form-select>
          </b-col>
          <b-col sm="2" v-if="viewInput4NewGroup" class="inputLabel">
            <label>グループ名</label>
          </b-col>
          <b-col sm="4" v-if="viewInput4NewGroup">
            <b-form-input placeholder="入力してください。"
              :value="newGroupName"
              @input="updateNewGroupName"/>
          </b-col>
        </b-row>
      </div>

      <div id="boxWrap" class="col-12 mt-3 px-1"
        v-if="type!==0">
        <b-tabs>
          <b-tab title="基本設定" active>
            <v-base/>
          </b-tab>
          <!-- <b-tab title="バリデーション">
            <v-validation/>
          </b-tab> -->
          <!-- <b-tab title="プレビュー" active>
            <v-preview/>
          </b-tab> -->
        </b-tabs>
      </div>

      <div class="bottonArea mt-3 mb-3 flex flexCenter">
        <b-button class="btn btn-lg mx-1" to="/cms/contents/fields">戻る</b-button>
        <b-button
          v-if="type!==0"
          variant="info"
          class="btn btn-primary btn-lg mx-1"
          @click="save">送信</b-button>
        <!-- <b-button class="btn btn-lg mx-1"
          v-if="id"
          variant="success"
          :to="'/cms/contents/fields/copy/' + id"
          >複製</b-button> -->
        <b-button class="btn btn-lg mx-1"
          v-if="id"
          @click="destroy(id)">削除</b-button>
      </div>
    </div>
    <v-popup-validation :id="'validationPopup'"/>
  </div>
</template>

<script>
import api from '@/modules/api';
import Base from '@/components/cms/contents/fields/Base.vue';
// import Validation from '@/components/cms/contents/fields/Validation.vue';
// import Preview from '@/components/cms/contents/fields/Preview.vue';
import CONST_OPTIONS from '@/constants/options';
import PopupValidation from '@/components/cms/contents/fields/popups/Validation.vue';

export default {
  name: 'CmsContentsFiledsEdit',
  components: {
    'v-base': Base,
    // 'v-validation': Validation,
    // 'v-preview': Preview,
    'v-popup-validation': PopupValidation,
  },
  data() {
    return {
      groupList: [],
      CONST_FIELD_TYPE: CONST_OPTIONS.FIELD_TYPE,
    };
  },
  computed: {
    viewInput4NewGroup() {
      return this.group === 0;
    },
    viewTypeChoises() {
      const isCheck = this.type === this.CONST_FIELD_TYPE.CHECKBOX.value;
      const isRadio = this.type === this.CONST_FIELD_TYPE.RADIO.value;
      const isSelect = this.type === this.CONST_FIELD_TYPE.SELECT.value;
      return isCheck || isRadio || isSelect;
    },
    viewTypeTexts() {
      const isText = this.type === this.CONST_FIELD_TYPE.TEXT.value;
      const isTextArea = this.type === this.CONST_FIELD_TYPE.TEXTAREA.value;
      return isText || isTextArea;
    },
    id() {
      return this.$store.state.cmsContentsField.id;
    },
    type() {
      return this.$store.state.cmsContentsField.type;
    },
    name() {
      return this.$store.state.cmsContentsField.name;
    },
    group_id() {
      return this.$store.state.cmsContentsField.group_id;
    },
    newGroupName() {
      return this.$store.state.cmsContentsField.newGroupName;
    },
  },
  methods: {
    async initFetch(promiseFuncs, fieldId) {
      return api.all(promiseFuncs)
        .then((responses) => {
          this.groupList = responses[0].data.groupList;
          if (fieldId) {
            const dbFieldData = responses[1].data;
            if (dbFieldData.field !== null) {
              this.$store.commit('cmsContentsField/setDbData', dbFieldData);
            } else {
              // データがなかった場合は新規登録ページへ移動する
              this.$router.push({ name: 'cmsContentsFiledsCreate' });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async save() {
      this.$store.dispatch('page/onWaiting');
      const params = this.$store.state.cmsContentsField;
      const response = await api.send('/api/contents/fields/save', params)
        .catch((err) => {
          console.error(err);
          this.$store.dispatch('page/offWaiting');
          return false;
        });
      if (!response) {
        return;
      }
      this.$store.dispatch('page/offWaiting');
    },
    async destroy(id) {
      this.$store.dispatch('page/onWaiting');
      const param = {
        id,
      };
      const response = await api.send('/api/contents/fields/destroy', param)
        .catch((err) => {
          console.error(err);
          return false;
        });
      if (!response) {
        return;
      }
      if (await this.confirm('削除しました。')) {
        this.$store.dispatch('page/offWaiting');
        this.$router.push({ name: 'cmsContentsItemList' });
      }
    },
    updateType(value) {
      const isCheck = value === this.CONST_FIELD_TYPE.CHECKBOX.value;
      const isRadio = value === this.CONST_FIELD_TYPE.RADIO.value;
      const isSelect = value === this.CONST_FIELD_TYPE.SELECT.value;
      const initOptionNum = isCheck || isRadio || isSelect ? 2 : 1;
      this.$store.commit('cmsContentsField/initOptions', initOptionNum);
      this.$store.commit('cmsContentsField/updateTemplate', 0);
      this.$store.commit('cmsContentsField/updateType', value);
    },
    updateName(value) {
      this.$store.commit('cmsContentsField/updateName', value);
    },
    updateGroup(value) {
      this.$store.commit('cmsContentsField/updateGroup', value);
    },
    updateNewGroupName(value) {
      this.$store.commit('cmsContentsField/updateNewGroupName', value);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    // 初期化
    this.$store.commit('cmsContentsField/initField');
    const promiseFuncs = [api.send('/api/contents/fields/group/list')];
    // 編集の時はルートパラメータ取得し、サーバーにデータを要求する
    let fieldId = null;
    if (this.$route.name === 'cmsContentsFiledsEdit') {
      fieldId = this.$route.params.field_id;
      const param = { field_id: this.$route.params.field_id };
      promiseFuncs.push(api.send('/api/contents/fields/edit', param));
    }
    // バリデーションルールが未取得なら取得する（通常1ログインに1回）
    if (this.$store.state.validation.rules.length === 0) {
      promiseFuncs.push(this.$store.dispatch('validation/fetchRules', api));
    }
    await this.initFetch(promiseFuncs, fieldId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .unicon {
    cursor : pointer;
  }
</style>
